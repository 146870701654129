import { useState, useEffect } from "react";
import mergeImages from "merge-images";
import { saveAs } from "file-saver";
import { images } from "./assets/helmets";
const heart = require("./assets/heart.png");

function App() {
  const [source, setSource] = useState(null);
  const [error, setError] = useState(false);
  const [input, setInput] = useState("");
  const [team, setTeam] = useState("");

  const downloadImage = () => {
    saveAs(source, "footballskull");
  };
  const handleSearch = () => {
    if (!input) {
      return setError("*** Please enter a Token ID ***");
    }
    if (!team) {
      return setError("*** Please choose a team ***");
    }
    const options = { method: "GET" };
    fetch(
      `https://api.opensea.io/api/v1/asset/0xc1caf0c19a8ac28c41fe59ba6c754e4b9bd54de9/${input}`,
      options
    )
      .then((response) => response.json())
      .then((response) => {
        download(response.image_url);
        setError(null);
      })
      .catch((err) => console.error(err));
  };

  const download = (openseaUrl) => {
    fetch(openseaUrl, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          init(url);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const init = (url) => {
    mergeImages([url, team]).then((b64) => setSource(b64));
  };

  return (
    <div className="bg-slate-100 min-h-screen flex flex-col justify-center items-center px-3">
      <h1 className="mb-4 text-6xl title text-center sm:mb-6 md:mb-10 md:text-7xl lg:mb-12 lg:text-8xl">
        Football Skulls
      </h1>
      <form className="w-full max-w-lg bg-white rounded-lg py-6 px-3 flex flex-col justify-center shadow-md">
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Skull ID
          </label>
          <input
            className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
              error && "border-red-500"
            }  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
            type="text"
            name="TokenID"
            value={input}
            onChange={(text) => setInput(text.target.value)}
            placeholder="Token ID"
            maxLength={4}
          />
        </div>
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Team
          </label>
          <div className="relative">
            <select
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              placeholder="Select a Team"
              value={team}
              onChange={(e) => setTeam(e.target.value)}
            >
              <option value="">Choose your Team</option>
              <option value={images.sf}>49ers</option>
              <option value={images.bears}>Bears</option>
              <option value={images.bengals}>Bengals</option>
              <option value={images.bills}>Bills</option>
              <option value={images.broncos}>Broncos</option>
              <option value={images.browns}>Browns</option>
              <option value={images.bucs}>Bucs</option>
              <option value={images.cardinals}>Cardinals</option>
              <option value={images.chargers}>Chargers</option>
              <option value={images.cheifs}>Cheifs</option>
              <option value={images.colts}>Colts</option>
              <option value={images.commanders}>Commanders</option>
              <option value={images.cowboys}>Cowboys</option>
              <option value={images.dolphins}>Dolphins</option>
              <option value={images.eagles}>Eagles</option>
              <option value={images.falcons}>Falcons</option>
              <option value={images.giants}>Giants</option>
              <option value={images.jaguars}>Jaguars</option>
              <option value={images.jets}>Jets</option>
              <option value={images.lions}>Lions</option>
              <option value={images.packers}>Packers</option>
              <option value={images.panthers}>Panthers</option>
              <option value={images.patriots}>Patriots</option>
              <option value={images.raiders}>Raiders</option>
              <option value={images.rams}>Rams</option>
              <option value={images.ravens}>Ravens</option>
              <option value={images.saints}>Saints</option>
              <option value={images.seahawks}>Seahawks</option>
              <option value={images.steelers}>Steelers</option>
              <option value={images.texans}>Texans</option>
              <option value={images.titans}>Titans</option>
              <option value={images.vikings}>Vikings</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
          {error && <p className="text-red-500 text-xs italic mt-2">{error}</p>}
        </div>
        <div>
          <button
            onClick={handleSearch}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 ml-3 mt-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
          >
            Search
          </button>
        </div>

        {source ? (
          <>
            <div className="flex justify-center py-4">
              <img src={source} className="w-60" crossOrigin="anonymous" />
            </div>

            <div className="hidden sm:flex">
              <button
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center mx-auto"
                onClick={downloadImage}
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>Download</span>
              </button>
            </div>
          </>
        ) : null}
      </form>
      <div className="flex items-center mt-4">
        <p className="">Made with</p>
        <img src={heart} className="w-4 h-4 mx-1" />
        <p>
          by{" "}
          <a
            href="https://twitter.com/zmeyer44"
            target="_blank"
            className="text-blue-600"
          >
            Zachm.eth
          </a>
        </p>
      </div>
      <div className="flex items-center mt-1">
        <p className="text-sm text-slate-500">
          Any contributions are greatly appreciated
        </p>
      </div>
    </div>
  );
}

export default App;
