export const images = {
  sf: require("./49ers.png"),
  bears: require("./bears.png"),
  bengals: require("./bengals.png"),
  bills: require("./bills.png"),
  broncos: require("./broncos.png"),
  browns: require("./browns.png"),
  bucs: require("./bucs.png"),
  cardinals: require("./cardinals.png"),
  chargers: require("./chargers.png"),
  cheifs: require("./cheifs.png"),
  colts: require("./colts.png"),
  commanders: require("./commanders.png"),
  cowboys: require("./cowboys.png"),
  dolphins: require("./dolphins.png"),
  eagles: require("./eagles.png"),
  falcons: require("./falcons.png"),
  giants: require("./giants.png"),
  jaguars: require("./jaguars.png"),
  jets: require("./jets.png"),
  lions: require("./lions.png"),
  packers: require("./packers.png"),
  panthers: require("./panthers.png"),
  patriots: require("./patriots.png"),
  raiders: require("./raiders.png"),
  rams: require("./rams.png"),
  ravens: require("./ravens.png"),
  saints: require("./saints.png"),
  seahawks: require("./seahawks.png"),
  steelers: require("./steelers.png"),
  texans: require("./texans.png"),
  titans: require("./titans.png"),
  vikings: require("./vikings.png"),
};
